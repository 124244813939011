import React from 'react';
import { motion } from 'framer-motion';

const pageVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    },
};

const pageTransition = {
    type: 'tween',
    ease: 'easeInOut',
    delay: 1,
};

const ghostVariants = {
    initial: {
        opacity: 0.1,
    },
    in: {
        opacity: 0.9,
    },
    out: {
        opacity: 0.1,
    },
};

const ghostTransition: any = {
    type: 'tween',
    ease: 'easeInOut',
    delay: 1,
    repeat: Infinity,
    repeatType: "mirror",
    duration: 2,
    repeatDelay: 1
};

const Trick = () => {
    return (
        <>
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
            >
                <div className="ContentWrap">
                    <div className="ContentMain">
                        <h1>
                            <img src="../images/hazure.png" className="Result" alt="trickortreat" />
                        </h1>
                        <motion.div
                            initial="initial"
                            animate="in"
                            exit="out"
                            variants={ghostVariants}
                            transition={ghostTransition}
                        >
                            <div>
                                <img src="../images/trick.png" className="ResultImage" alt="trickortreat" />
                            </div>
                        </motion.div>
                        <p className="Explain">ざ～んねん<br />おいしいお菓子はおあずけ</p>

                    </div>
                </div>
            </motion.div>

        </>
    );
};

export default Trick;