import React from 'react';
import { motion } from 'framer-motion';

const pageVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    },
};

const pageTransition = {
    type: 'tween',
    ease: 'easeInOut',
    delay: 1,
};

const Treat = () => {
    return (
        <>
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
            >
                <div className="ContentWrap">
                    <div className="ContentMain">
                        <h1>
                            <img src="../images/atari.png" className="Result" alt="trickortreat" />
                        </h1>
                        <div>
                            <img src="../images/treat.png" className="ResultImage" alt="trickortreat" />
                        </div>
                        <p className="Explain">おめでとう<br />おいしいお菓子をどうぞ</p>
                    </div>
                </div>

            </motion.div>
        </>
    );
};

export default Treat;