import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Welcome from './pages/Welcome';
import TrickOrTreat from './pages/TrickOrTreat';
import Trick from './pages/Trick';
import Treat from './pages/Treat';

const AnimateRoutes: React.FC = () => {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path={`/`} element={<Welcome />} />
                <Route path={`/trickortreat`} element={<TrickOrTreat />} />
                <Route path={`/trick`} element={<Trick />} />
                <Route path={`/treat`} element={<Treat />} />
            </Routes>
        </AnimatePresence>
    );
};

export default AnimateRoutes;