import React from 'react';
import './App.scss';
import { BrowserRouter } from "react-router-dom";
import AnimateRoutes from './AnimateRoutes';

function App() {
  return (
    <BrowserRouter basename="/">
      <AnimateRoutes />
    </BrowserRouter>
  );
}

export default App;
