import React from 'react';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';

const pageVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    },
};

const pageTransition = {
    type: 'tween',
    ease: 'easeInOut',
    delay: 1,
};

const TrickOrTreat = () => {

    // トリックオアトリートを左右の扉にランダムで配置する
    const point: number = Math.random()

    const DoorUrl = (which: string, point: number) => {
        if (
            which == "left" && point < 0.5
            || which == "right" && point >= 0.5
        ) {
            return "/treat/"
        }
        return "/trick/"
    }

    return (
        <>
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
            >
                <div className="ContentWrap">
                    <div className="ContentMain">
                        <h1 className="NoMargin">
                            <img src="../images/trickortreat.png" className="HalloweenTop" alt="trickortreat" />
                        </h1>

                        <p className="Explain">いたずらか、お菓子か<br />どちらかの扉を開けてね</p>

                        <div className="Doors">
                            <Link to={DoorUrl("left", point)}>
                                <img src="../images/door.png" className="Door" alt="door" />
                            </Link>
                            <Link to={DoorUrl("right", point)}>
                                <img src="../images/door.png" className="Door" alt="door" />
                            </Link>
                        </div>
                    </div>
                </div>

            </motion.div>
        </>
    );
};

export default TrickOrTreat;